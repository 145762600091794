main {
  display: flex;
  margin: 1em;
}

section {
  flex: 10 10 0;
  padding-bottom: 2em;
  width: 100%;
}

aside {
  display: block;
  flex: 3 3 0;
  margin: 1em;
}

table {
  max-width: 100%;
}

.table-container {
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  aside {
    display: none;
  }
}

.title {
  text-align: center;
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.vbesort-title {
  text-align: center;
  font-size: 70px;
  margin-top: 50px;
  margin-bottom: 10px;
}

.vbesort-description {
  font-style: italic;
  text-align: center;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 50px;
}

footer {
  text-align: center;
  padding-bottom: 60px;
}

[data-bs-theme="dark"] img::after {
  content: attr(alt);
  color: black;
}

[data-bs-theme="light"] img::after {
  content: attr(alt);
  color: white;
}

.root-header > .accordion-button {
  padding: 5px 10px 5px 5px;
  background-color: aliceblue;
  /* background-color: rgb(207, 226, 255); */
}

[data-bs-theme="dark"] .root-header > .accordion-button {
  background-color: var(--background-color);
}

[data-bs-theme="dark"] .single-problem img {
  filter: invert(1);
}
